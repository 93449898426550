<template>
    <div>
        <el-collapse v-model="activeCollapse" class="yanForm animated fadeInUp">
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.resimKonum")' name="9">
                <el-row>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                        <el-form-item prop="resimKonum">
                            <el-button type="primary" size="mini" @click="dialogResimKonum = true">
                                <label v-if="resimKonum === '0'">Seç</label>
                                <label v-else>Değiştir</label>
                            </el-button>
                            &nbsp;
                            <img v-if="resimKonum === '0'" style="float: right"
                                 src="../assets/images/1.png" width="50%" height="50%">
                            <img v-if="resimKonum === '1'" style="float: right"
                                 src="../assets/images/2.png" width="50%" height="50%">
                            <img v-if="resimKonum === '2'" style="float: right"
                                 src="../assets/images/5.png" width="50%" height="50%">
                            <img v-if="resimKonum === '3'" style="float: right"
                                 src="../assets/images/4.png" width="50%" height="50%">
                            <img v-if="resimKonum === '4'" style="float: right"
                                 src="../assets/images/3.png" width="50%" height="50%">
                        </el-form-item>
                        <div style="font-style: italic">
                            {{$t("src.views.apps.sayfa.tanimlama.resimKonumAciklama")}}
                        </div>
                    </el-col>
                </el-row>
            </el-collapse-item>
            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.sayfa.tanimlama.menuKonum")' name="10">
                <el-row>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                        <el-form-item prop="menuKonum">
                            <el-button type="primary" size="mini" @click="dialogMenuKonum = true">
                                <label v-if="menuKonum === '0'">Seç</label>
                                <label v-else>Değiştir</label>
                            </el-button>
                            &nbsp;
                            <img v-if="menuKonum === '0'" style="float: right"
                                 src="../assets/images/1.png" width="50%" height="50%">
                            <img v-if="menuKonum === '1'" style="float: right"
                                 src="../assets/images/6.png" width="50%" height="50%">
                            <img v-if="menuKonum === '2'" style="float: right"
                                 src="../assets/images/9.png" width="50%" height="50%">
                        </el-form-item>
                        <div style="font-style: italic">
                            {{$t("src.views.apps.sayfa.tanimlama.menuKonumAciklama")}}
                        </div>
                    </el-col>
                </el-row>
            </el-collapse-item>
            <el-collapse-item class="card-shadow--medium" style="margin-bottom: 10px" :title='$t("src.views.apps.sayfa.tanimlama.galeriKonum")' name="11">
                <el-row>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                        <el-form-item prop="galeriKonum">
                            <el-button type="primary" size="mini" @click="dialogGaleriKonum = true">
                                <label v-if="galeriKonum === '0'">Seç</label>
                                <label v-else>Değiştir</label>
                            </el-button>
                            &nbsp;
                            <img v-if="galeriKonum === '0'" style="float: right"
                                 src="../assets/images/1.png" width="50%" height="50%">
                            <img v-if="galeriKonum === '1'" style="float: right"
                                 src="../assets/images/7.png" width="50%" height="50%">
                            <img v-if="galeriKonum === '2'" style="float: right"
                                 src="../assets/images/8.png" width="50%" height="50%">
                        </el-form-item>
                        <div style="font-style: italic">
                            {{$t("src.views.apps.sayfa.tanimlama.galeriKonumAciklama")}}
                        </div>
                    </el-col>
                </el-row>
            </el-collapse-item>
        </el-collapse>

        <el-dialog title="Resim Konumu" :visible.sync="dialogResimKonum" width="60%" append-to-body>
            <el-row>
                <el-col :span="8">
                    <el-radio v-model="resimKonum" label="0" @change="changeKonum('resim','0')">
                        <img src="../assets/images/1.png" alt="Yok" width="90%" height="90%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="resimKonum" label="1" @change="changeKonum('resim','1')">
                        <img src="../assets/images/2.png" alt="Sağ" width="90%" height="90%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="resimKonum" label="2" @change="changeKonum('resim','2')">
                        <img src="../assets/images/5.png" alt="Alt" width="90%" height="90%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="resimKonum" label="3" @change="changeKonum('resim','3')">
                        <img src="../assets/images/4.png" alt="Sol" width="90%" height="90%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="resimKonum" label="4" @change="changeKonum('resim','4')">
                        <img src="../assets/images/3.png" alt="Üst" width="90%" height="90%">
                    </el-radio>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog title="Menü Konumu" :visible.sync="dialogMenuKonum" width="50%" append-to-body>
            <el-row>
                <el-col :span="8">
                    <el-radio v-model="menuKonum" label="0" @change="changeKonum('menu','0')">
                        <img src="../assets/images/1.png" alt="Yok" width="80%" height="80%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="menuKonum" label="1" @change="changeKonum('menu','1')">
                        <img src="../assets/images/6.png" alt="Sağ" width="80%" height="80%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="menuKonum" label="2" @change="changeKonum('menu','2')">
                        <img src="../assets/images/9.png" alt="Alt" width="80%" height="80%">
                    </el-radio>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog title="Galeri Konumu" :visible.sync="dialogGaleriKonum" width="50%" append-to-body>
            <el-row>
                <el-col :span="8">
                    <el-radio v-model="galeriKonum" label="0" @change="changeKonum('galeri','0')">
                        <img src="../assets/images/1.png" alt="Yok" width="80%" height="80%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="galeriKonum" label="1" @change="changeKonum('galeri','1')">
                        <img src="../assets/images/7.png" alt="Dikey,Büyük" width="80%" height="80%">
                    </el-radio>
                </el-col>
                <el-col :span="8">
                    <el-radio v-model="galeriKonum" label="2" @change="changeKonum('galeri','2')">
                        <img src="../assets/images/8.png" alt="Yatay,Küçük" width="80%">
                    </el-radio>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    import notification from '../notification'

    export default {
        name: "Konumlar",
        props: ['modulName','resim','menu','galeri'],
        watch: {
            resim: function (val) {
                if(val){
                    this.resimKonum = val
                }
            },
            menu: function (val) {
                if(val){
                    this.menuKonum = val
                }
            },
            galeri: function (val) {
                if(val){
                    this.galeriKonum = val
                }
            }
        },
        data() {
            return {
                activeCollapse: ['9', '10', '11'],

                resimKonum: "0",
                menuKonum: "0",
                galeriKonum: "0",

                dialogResimKonum: false,
                dialogMenuKonum: false,
                dialogGaleriKonum: false,
            }
        },

        // this.$emit('changeSeo', this.seoBaslik, this.seoIcerik)

        methods: {
            changeKonum(item, konum) {
                if (item === 'resim') {
                    this.dialogResimKonum = false;
                    this.resimKonum = konum;
                    this.$emit('changeKonum', this.resimKonum, this.menuKonum, this.galeriKonum)
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Resim konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Resim konumu "Sağ" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Resim konumu "Alt" olarak seçildi.');
                            break;
                        case '3' :
                            notification.Status('success', this, 'Resim konumu "Sol" olarak seçildi.');
                            break;
                        case '4' :
                            notification.Status('success', this, 'Resim konumu "Üst" olarak seçildi.');
                            break;
                    }
                } else if (item === 'menu') {
                    this.dialogMenuKonum = false;
                    this.menuKonum = konum;
                    this.$emit('changeKonum', this.resimKonum, this.menuKonum, this.galeriKonum)
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Menü konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Menü konumu "Sağ" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Menü konumu "Alt" olarak seçildi.');
                            break;
                    }
                } else if (item === 'galeri') {
                    this.dialogGaleriKonum = false;
                    this.galeriKonum = konum;
                    this.$emit('changeKonum', this.resimKonum, this.menuKonum, this.galeriKonum)
                    switch (konum) {
                        case '0' :
                            notification.Status('success', this, 'Galeri konumu "Yok" olarak seçildi.');
                            break;
                        case '1' :
                            notification.Status('success', this, 'Galeri konumu "Dikey,Büyük" olarak seçildi.');
                            break;
                        case '2' :
                            notification.Status('success', this, 'Galeri konumu "Yatay,Küçük" olarak seçildi.');
                            break;
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>