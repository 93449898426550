<template>
    <div>
        <el-row>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.seoBaslik")' prop="seoBaslik">:
                    <el-input size="small" disabled id="seoBaslik" v-model="seoBaslik" @input="seoBaslikChange('seoBaslik')" :placeholder='$t("src.views.apps.sayfa.tanimlama.seoBaslikPlace")'>
                        <template slot="append">{{seoBaslikLimit}}</template>
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.sayfa.tanimlama.seoIcerik")' prop="seoIcerik">:
                    <el-input size="small" disabled type="textarea" v-model="seoIcerik"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import JQuery from 'jquery';
    let $ = JQuery;
    import functions from '../functions'

    export default {
        name: "Seo",
        props: ['modulName','baslik','icerik','seoBaslikF','seoIcerikF'],
        watch: {
            baslik: function (val) {
                if(val != ''){
                    this.seoBaslik = val.substring(0, 60);
                }
            },
            icerik: function (val) {
                if(val != ''){
                    const myStr = functions.strip_html_tags(val);
                    this.seoIcerik = myStr.substring(0, 140);
                }
            },
            seoBaslikF: function (val) {
                if(val != ''){
                    this.seoBaslik = val;
                }
            },
            seoIcerikF: function (val) {
                if(val != ''){
                    this.seoIcerik = val;
                }
            },
            seoBaslik: function (val) {
                if(val != ''){
                    this.$emit('changeSeo', this.seoBaslik, this.seoIcerik)
                }
            },
            seoIcerik: function (val) {
                if(val != ''){
                    this.$emit('changeSeo', this.seoBaslik, this.seoIcerik)
                }
            },
        },
        data(){
            return{
                seoBaslik: this.seoBaslikF,
                seoIcerik: this.seoIcerikF,
                seoBaslikLimit: 0,
            }
        },
        methods:{
            seoBaslikChange(id) {
                this.seoBaslikLimit = this.seoBaslik.length;
                if (this.seoBaslikLimit > 70) {
                    $('#' + id).closest('div').find('div').addClass('limitAsim');
                } else {
                    $('#' + id).closest('div').find('div').removeClass('limitAsim');
                }
            },
        }
    }
</script>

<style scoped>

</style>